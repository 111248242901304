import '@babel/polyfill';
import svg4everybody from 'svg4everybody';
import $ from 'jquery';
import './vendor/lenis';
import 'gsap';

svg4everybody();

window.$ = $;
window.jQuery = $;

require('ninelines-ua-parser');
require('./vendor/ScrollMagic');
require('./vendor/animation.gsap.js');
