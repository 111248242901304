import './vendor';
import marquee from 'jquery.marquee';
import 'jquery-validation';
import inputmask from 'inputmask/dist/jquery.inputmask';
import Swiper from 'swiper/js/swiper.js';

const $document = $(document);

const $window = $(window);

const $html = $('html');

const $body = $('body');

if (!(!!window.MSInputMethodContext && !!document.documentMode)) {
	let vh = window.innerHeight;

	document.documentElement.style.setProperty('--vh', `${vh}px`);

	window.addEventListener('resize', () => {
		vh = window.innerHeight;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});
}

let app = {
	mobile() {
		if ($window.outerWidth() < 1024) {
			return true;
		}

		return false;
	},
	vw(value, width) {
		if (innerWidth < 1024) {
			return value / 320 * innerWidth;
		}

		return value / 1600 * innerWidth;
	},
	vh(value) {
		return value / 1080 * innerHeight;
	},
	global: {
		init() {
			if (!window.$body) {
				window.$body = $('html, body');
			}
			$document.on('click', '.js-goto', (e) => {
				let $this = $(e.currentTarget);

				let target = $this.attr('href') || $this.data('target');

				e.preventDefault();
				if ($html.hasClass('is-custom-scroll')) {
					window.$body.scrollTop($(target), 1000);
				} else {
					window.scrollTo({
						top: $(target).offset().top,
						left: 0,
						behavior: 'smooth',
					});
					// window.$body.animate({
					// 	scrollTop: $(target).offset().top,
					// }, 500);
				}
			});
		},
	},
	menu: {
		init($menu) {
			let $typeButton = $menu.find('.menu__type button');

			let $row = $menu.find('.menu__row');

			$('.menu-button').on('click', (e) => {
				e.preventDefault();
				$html.toggleClass('is-show-menu');
			});

			$menu.find('.menu__row a').hover(
				() => {
					$menu.addClass('is-link-hover');
				},
				() => {
					$menu.removeClass('is-link-hover');
				}
			);

			$typeButton.on('click', (e) => {
				let $this = $(e.currentTarget);

				e.preventDefault();
				if (!$this.hasClass('is-active')) {
					$typeButton.removeClass('is-active');
					$row.removeClass('is-active');
					$this.addClass('is-active');
					$row.filter(`[data-type="${$this.data('type')}"]`).addClass('is-active');
				}
			});
		},
	},
	preloader: {
		$container: $('.preloader'),
		$dot: $('.preloader__dot'),
		$progress: $('.preloader__progress'),
		init($preloader) {
			if (!$preloader) {
				$preloader = $('.preloader');
			}

			let resourcesCount = 0;

			let loadedResources = 0;

			let counter = {
				val: 0,
			};

			const MIN_LOAD_TIME_MS = 1000;

			const $dot = $preloader.find('.preloader__dot');

			const $progress = $preloader.find('.preloader__progress');

			const $background = $preloader.find('.preloader__background');

			let initPreloader = () => {
				$dot.show();
				TweenMax.from($dot, MIN_LOAD_TIME_MS / 1000, {
					scale: 0,
				});
			};

			let animateNum = (val) => {
				return new Promise((resolve) => {
					TweenLite.killTweensOf(counter);

					TweenLite.to(counter, 0.5, {
						roundProps: 'value',
						val,
						onUpdate() {
							$progress.text(`${Math.ceil(counter.val)}%`);
						},
						onComplete() {
							resolve();
						},
					});

					if (val < 100) {
						resolve();
					}
				});
			};

			let setProgress = () => {
				loadedResources++;

				let progress = Math.floor(loadedResources * 100 / resourcesCount);

				return animateNum(progress);
			};

			let loadResource = (resource) => {
				return new Promise((resolve, reject) => {
					if (!resource) {
						setProgress().then(reject);

						return;
					}

					const img = new Image();

					img.onload = () => {
						setProgress().then(resolve);
					};

					img.onerror = () => {
						setProgress().then(reject);
					};

					img.src = resource.src;
				});
			};

			let loadResources = () => {
				const $resources = $(document.body).find('img');

				resourcesCount = $resources.length;
				if ($resources.length === 0) {
					TweenMax.to(counter, MIN_LOAD_TIME_MS / 1000, {
						roundProps: 'value',
						val: 100,
						onUpdate() {
							$progress.text(`${Math.ceil(counter.val)}%`);
						},
					});
				}

				return Promise.all($resources.map((index, resource) => loadResource(resource)));
			};

			let minDelay = () => {
				return new Promise((resolve) => {
					setTimeout(resolve, MIN_LOAD_TIME_MS);
				});
			};

			let hide = () => {
				let delay = () => {
					$html.removeClass('is-state-preloader');
					TweenMax.to($background, 0.5, {
						autoAlpha: 0,
						onComplete() {
							$background.hide();
						},
					});

					if ($('.page-index').length) {
						app.preloader.animation.index();
					} else if ($('.page-about').length) {
						app.preloader.animation.about();
					} else if ($('.page-digital').length) {
						app.preloader.animation.digital();
					} else if ($('.page-estate').length) {
						app.preloader.animation.estate();
					} else {
						app.preloader.animation.default();
					}
				};

				setTimeout(delay, 300);
			};

			return Promise.all([initPreloader(), loadResources(), minDelay()]).then(hide);
		},
		animation: {
			index() {
				$window.scrollTop(0);
				if (app.mobile()) {
					new TimelineMax()
						.from($('.page-index__slogan-title__text span'), 0.75, {
							y: '100%',
							opacity: 0,
							ease: Sine.easeInOut,
						}, 0.25)
						.to(app.preloader.$dot, 0.35, {
							left: app.vw(214),
							top: app.vw(335),
							width: app.vw(107),
							height: app.vw(107),
							ease: Sine.easeInOut,
							onComplete() {
								new TimelineMax()
									.to(app.preloader.$dot, 0.2, {
										left: app.vw(224),
										top: app.vw(345),
										width: app.vw(87),
										height: app.vw(87),
										backgroundColor: '#cfb569',
										ease: Sine.easeInOut,
										opacity: 1,
										onComplete() {
											TweenMax.to(app.preloader.$container, 0.5, {
												autoAlpha: 0,
												onComplete() {
													app.preloader.$container.hide();
												},
											});
										},
									}, 0);
							},
						}, 0.15)
						.from($('.page-index__start-arrow'), 0, {
							opacity: 0,
							ease: Sine.easeInOut,
						}, 0.7);
				} else {
					new TimelineMax()
						.to(app.preloader.$dot, 0.35, {
							left: app.vw(1306),
							bottom: app.vw(266),
							width: app.vw(305),
							height: app.vw(305),
							ease: Sine.easeInOut,
							onComplete() {
								new TimelineMax()
									.to(app.preloader.$dot, 0.2, {
										left: app.vw(1366),
										bottom: app.vw(326),
										width: app.vw(185),
										height: app.vw(185),
										backgroundColor: '#cfb569',
										ease: Sine.easeInOut,
										opacity: 1,
										onComplete() {
											TweenMax.to(app.preloader.$container, 0.5, {
												autoAlpha: 0,
												onComplete() {
													app.preloader.$container.hide();
												},
											});
										},
									}, 0);
							},
						}, 0.15);
				}
				new TimelineMax()
					.to(app.preloader.$progress, 0.25, {
						y: '-100%',
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0)
					.from($('.page-index__description__title__text span'), 0.75, {
						y: '100%',
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0.25)
					.from($('.page-index__slogan-text__text:nth-child(1) span'), 0.75, {
						y: '100%',
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0.35)
					.from($('.page-index__slogan-text__text:nth-child(2) span'), 0.75, {
						y: '100%',
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0.45)
					.from($('.page-index__description__dot'), 0, {
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0.7)
					.from($('.header, .win-rate, .page-index__slogan-name'), 1, {
						opacity: 0,
						ease: Sine.easeInOut,
					}, 1)
					.from($('.menu-button'), 1, {
						x: app.vw(100),
						opacity: 0,
						ease: Sine.easeInOut,
						onComplete() {
							$html.addClass('is-menu-button-transition');
						},
					}, 1);
			},
			about() {
				$window.scrollTop(0);
				if (app.mobile()) {
					new TimelineMax()
						.to(app.preloader.$dot, 0.5, {
							autoAlpha: 0,
							ease: Sine.easeInOut,
							onComplete() {
								app.preloader.$container.hide();
							},
						}, 0.15);
				} else {
					new TimelineMax()
						.to(app.preloader.$dot, 0.35, {
							autoAlpha: 0,
							ease: Sine.easeInOut,
							onComplete() {
								TweenMax.to(app.preloader.$container, 0.5, {
									autoAlpha: 0,
									onComplete() {
										app.preloader.$container.hide();
									},
								});
							},
						}, 0.15);
				}
				new TimelineMax()
					.to(app.preloader.$progress, 0.25, {
						y: '-100%',
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0)
					.from($('.page-about__intro__label'), 0.5, {
						autoAlpha: 0,
						y: app.vw(50),
						ease: Sine.easeInOut,
					}, 0.25)
					.from($('.page-about__intro__title'), 0.5, {
						autoAlpha: 0,
						y: app.vw(50),
						ease: Sine.easeInOut,
					}, 0.5)
					.from($('.page-about__intro__scroll'), 0.5, {
						autoAlpha: 0,
						ease: Sine.easeInOut,
					}, 0.75)
					.from($('.page-about__intro__title__svg'), 0.5, {
						strokeDashoffset: 1774,
						ease: Sine.easeInOut,
					}, 1)
					.from($('.header'), 1, {
						opacity: 0,
						ease: Sine.easeInOut,
					}, 1)
					.from($('.menu-button'), 1, {
						x: app.vw(100),
						opacity: 0,
						ease: Sine.easeInOut,
						onComplete() {
							$html.addClass('is-menu-button-transition');
						},
					}, 1.25);
			},
			digital() {
				$window.scrollTop(0);
				if (app.mobile()) {
					new TimelineMax()
						.to(app.preloader.$dot, 0.35, {
							left: app.vw(173),
							top: app.vw(64),
							width: app.vw(75),
							height: app.vw(75),
							ease: Sine.easeInOut,
							onComplete() {
								new TimelineMax()
									.to(app.preloader.$dot, 0.2, {
										left: app.vw(183),
										top: app.vw(74),
										width: app.vw(55),
										height: app.vw(55),
										backgroundColor: '#fff',
										ease: Sine.easeInOut,
										opacity: 1,
										onComplete() {
											TweenMax.to(app.preloader.$container, 0.5, {
												autoAlpha: 0,
												onComplete() {
													app.preloader.$container.hide();
												},
											});
										},
									}, 0);
							},
						}, 0.15)
						.from($('.back'), 0, {
							opacity: 0,
							ease: Sine.easeInOut,
						}, 0.7);
				} else {
					new TimelineMax()
						.to(app.preloader.$dot, 0.35, {
							left: app.vw(862),
							top: app.vw(-127),
							width: app.vw(165),
							height: app.vw(165),
							ease: Sine.easeInOut,
							onComplete() {
								new TimelineMax()
									.to(app.preloader.$dot, 0.2, {
										left: app.vw(882),
										top: app.vw(-107),
										width: app.vw(125),
										height: app.vw(125),
										backgroundColor: '#fff',
										ease: Sine.easeInOut,
										opacity: 1,
										onComplete() {
											TweenMax.to(app.preloader.$container, 0.5, {
												autoAlpha: 0,
												onComplete() {
													app.preloader.$container.hide();
												},
											});
										},
									}, 0);
							},
						}, 0.15)
						.from($('.back'), 0, {
							opacity: 0,
							ease: Sine.easeInOut,
						}, 0.7);
				}
				new TimelineMax()
					.to(app.preloader.$progress, 0.25, {
						y: '-100%',
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0)
					.from($('.page-digital__intro__title'), 0.5, {
						autoAlpha: 0,
						y: app.vw(50),
						ease: Sine.easeInOut,
					}, 0.25)
					.from($('.page-digital__intro__text'), 0.5, {
						autoAlpha: 0,
						y: app.vw(50),
						ease: Sine.easeInOut,
					}, 0.4)
					.from($('.header'), 1, {
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0.75)
					.from($('.menu-button'), 1, {
						x: app.vw(100),
						opacity: 0,
						ease: Sine.easeInOut,
						onComplete() {
							$html.addClass('is-menu-button-transition');
						},
					}, 1);
			},
			estate() {
				$window.scrollTop(0);
				if (app.mobile()) {
					new TimelineMax()
						.to(app.preloader.$dot, 0.35, {
							left: app.vw(173),
							top: app.vw(64),
							width: app.vw(75),
							height: app.vw(75),
							ease: Sine.easeInOut,
							onComplete() {
								new TimelineMax()
									.to(app.preloader.$dot, 0.2, {
										left: app.vw(183),
										top: app.vw(74),
										width: app.vw(55),
										height: app.vw(55),
										backgroundColor: '#4c0f28',
										ease: Sine.easeInOut,
										opacity: 1,
										onComplete() {
											TweenMax.to(app.preloader.$container, 0.5, {
												autoAlpha: 0,
												onComplete() {
													app.preloader.$container.hide();
												},
											});
										},
									}, 0);
							},
						}, 0.15)
						.from($('.back'), 0, {
							opacity: 0,
							ease: Sine.easeInOut,
						}, 0.7);
				} else {
					new TimelineMax()
						.to(app.preloader.$dot, 0.35, {
							left: app.vw(862),
							top: app.vw(-127),
							width: app.vw(165),
							height: app.vw(165),
							ease: Sine.easeInOut,
							onComplete() {
								new TimelineMax()
									.to(app.preloader.$dot, 0.2, {
										left: app.vw(882),
										top: app.vw(-107),
										width: app.vw(125),
										height: app.vw(125),
										backgroundColor: '#4c0f28',
										ease: Sine.easeInOut,
										opacity: 1,
										onComplete() {
											TweenMax.to(app.preloader.$container, 0.5, {
												autoAlpha: 0,
												onComplete() {
													app.preloader.$container.hide();
												},
											});
										},
									}, 0);
							},
						}, 0.15)
						.from($('.back'), 0, {
							opacity: 0,
							ease: Sine.easeInOut,
						}, 0.7);
				}
				new TimelineMax()
					.to(app.preloader.$progress, 0.25, {
						y: '-100%',
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0)
					.from($('.page-estate__intro__title'), 0.5, {
						autoAlpha: 0,
						y: app.vw(50),
						ease: Sine.easeInOut,
					}, 0.25)
					.from($('.page-estate__intro__text'), 0.5, {
						autoAlpha: 0,
						y: app.vw(50),
						ease: Sine.easeInOut,
					}, 0.4)
					.from($('.header'), 1, {
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0.75)
					.from($('.menu-button'), 1, {
						x: app.vw(100),
						opacity: 0,
						ease: Sine.easeInOut,
						onComplete() {
							$html.addClass('is-menu-button-transition');
						},
					}, 1);
			},
			default() {
				let backColor = '#cfb569';

				if ($html.hasClass('is-page-light')) {
					backColor = '#4c0f28';
				}

				$window.scrollTop(0);
				if (app.mobile()) {
					if ($('.back').length) {
						new TimelineMax()
							.to(app.preloader.$dot, 0.35, {
								left: app.vw(173),
								top: app.vw(64),
								width: app.vw(75),
								height: app.vw(75),
								ease: Sine.easeInOut,
								onComplete() {
									new TimelineMax()
										.to(app.preloader.$dot, 0.2, {
											left: app.vw(183),
											top: app.vw(74),
											width: app.vw(55),
											height: app.vw(55),
											backgroundColor: backColor,
											ease: Sine.easeInOut,
											opacity: 1,
											onComplete() {
												TweenMax.to(app.preloader.$container, 0.5, {
													autoAlpha: 0,
													onComplete() {
														app.preloader.$container.hide();
													},
												});
											},
										}, 0);
								},
							}, 0.15)
							.from($('.back'), 0, {
								opacity: 0,
								ease: Sine.easeInOut,
							}, 0.7);
					} else {
						new TimelineMax()
							.to(app.preloader.$dot, 0.5, {
								autoAlpha: 0,
								ease: Sine.easeInOut,
								onComplete() {
									app.preloader.$container.hide();
								},
							}, 0.15);
					}
				} else if ($('.back').length) {
					new TimelineMax()
						.to(app.preloader.$dot, 0.35, {
							left: app.vw(862),
							top: app.vw(-127),
							width: app.vw(165),
							height: app.vw(165),
							ease: Sine.easeInOut,
							onComplete() {
								new TimelineMax()
									.to(app.preloader.$dot, 0.2, {
										left: app.vw(882),
										top: app.vw(-107),
										width: app.vw(125),
										height: app.vw(125),
										backgroundColor: backColor,
										ease: Sine.easeInOut,
										opacity: 1,
										onComplete() {
											TweenMax.to(app.preloader.$container, 0.5, {
												autoAlpha: 0,
												onComplete() {
													app.preloader.$container.hide();
												},
											});
										},
									}, 0);
							},
						}, 0.15)
						.from($('.back'), 0, {
							opacity: 0,
							ease: Sine.easeInOut,
						}, 0.7);
				} else {
					new TimelineMax()
						.to(app.preloader.$dot, 0.35, {
							autoAlpha: 0,
							ease: Sine.easeInOut,
							onComplete() {
								TweenMax.to(app.preloader.$container, 0.5, {
									autoAlpha: 0,
									onComplete() {
										app.preloader.$container.hide();
									},
								});
							},
						}, 0.15);
				}
				new TimelineMax()
					.to(app.preloader.$progress, 0.25, {
						y: '-100%',
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0)
					.from($('.header, .page'), 1, {
						opacity: 0,
						ease: Sine.easeInOut,
					}, 0.25)
					.from($('.js-animate-title'), 0.5, {
						autoAlpha: 0,
						y: app.vw(50),
						ease: Sine.easeInOut,
					}, 0.25)
					.from($('.js-animate-text'), 0.5, {
						autoAlpha: 0,
						y: app.vw(50),
						ease: Sine.easeInOut,
					}, 0.35)
					.from($('.menu-button'), 1, {
						x: app.vw(100),
						opacity: 0,
						ease: Sine.easeInOut,
						onComplete() {
							$html.addClass('is-menu-button-transition');
						},
					}, 0.5);

				if ($('.js-animate-line').length) {
					$('.js-animate-line').css({
						strokeDasharray: $('.js-animate-line').find('path')[0].getTotalLength(),
					});
					new TimelineMax()
						.from($('.js-animate-line'), 1, {
							strokeDashoffset: $('.js-animate-line').find('path')[0].getTotalLength(),
						}, 0.75);
				}
			},
		},
	},
	page: {
		init($page) {
			app.preloader.init().then(() => {
				app.page.constructor($page);
			});
		},
		constructor($page) {
			if (app.mobile()) {
				if ($page.data('type') === 'index') {
					app.page.type.indexMobile();
				} else if ($page.data('type') === 'handling') {
					app.page.type.handlingMobile();
				}
			} else {
				let controller;

				let scenes = [];

				const lenis = new Lenis({
					duration: 2,
					easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
					direction: 'vertical', // vertical, horizontal
					gestureDirection: 'vertical', // vertical, horizontal, both
					smooth: true,
					mouseMultiplier: 1,
					smoothTouch: false,
					touchMultiplier: 2,
					infinite: false,
				});

				const raf = (time) => {
					lenis.raf(time);
					requestAnimationFrame(raf);
				};

				requestAnimationFrame(raf);

				controller = new ScrollMagic.Controller({
					refreshInterval: 0,
				});

				if ($page.data('type') && app.page.type[$page.data('type')]) {
					scenes = app.page.type[$page.data('type')](controller);
				} else {
					scenes = app.page.type.default(controller);
				}

				$page.find('.page-index__ticker').eq(0).marquee({
					duplicated: true,
					duration: 21500,
					delayBeforeStart: 0,
					gap: 0,
				});
			}
		},
		type: {
			index(controller) {
				return [new ScrollMagic.Scene({
					triggerElement: '#trigger',
					duration: app.vw(200),
					offset: 0,
				}).setTween(new TimelineMax().to($('.win-rate'), 1, {
					autoAlpha: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#trigger',
					duration: app.vw(400),
					offset: 0,
				}).setTween(new TimelineMax().to($('.page-index__slogan-text'), 1, {
					y: app.vw(-130),
					autoAlpha: 0,

				}, 0).to($('.page-index__slogan-name'), 1, {
					y: app.vw(-130),
					autoAlpha: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#trigger',
					duration: app.vw(600),
					offset: 0,
				})
					.setTween(new TimelineMax().to($('body'), 1, {
						backgroundColor: '#fff',
					}, 0)
						.to($('.header'), 1, {
							color: '#121212',

						}, 0)
						.to($('.cursor'), 1, {
							backgroundColor: '#26272a',

						}, 0)
						.to($('.page-index__description__title'), 1, {
							y: app.vw(-178),
							x: app.vw(100),
							color: '#4C0f28',
							opacity: '1',
							scale: 1,

						}, 0)
						.from($('.page-index__ticker'), 1, {
							opacity: '0',

						}, 0)
						.to($('.page-index__description__dot'), 1, {
							y: app.vw(-330),
							x: app.vw(159.9),
							width: app.vw(13),
							height: app.vw(13),
							padding: app.vw(6.5),

						}, 0))
					.addTo(controller).on('enter', () => {
						$('.page-index__description__title').removeClass('is-absolute');
						$('.page-index__description__dot').removeClass('is-absolute');
					})
					.on('leave', (event) => {
						if (event.state === 'AFTER') {
							$('.page-index__description__title').addClass('is-absolute');
							$('.page-index__description__dot').addClass('is-absolute');
						}
					}),

				new ScrollMagic.Scene({
					triggerElement: '#trigger',
					duration: app.vw(500),
					offset: app.vw(600),
				}).setPin('.page-index__description').addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#trigger',
					duration: app.vw(400),
					offset: app.vw(600),
				}).setTween(new TimelineMax().from($('.page-index__description__title svg'), 1, {
					strokeDashoffset: 1381,
				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#trigger',
					duration: app.vw(400),
					offset: app.vw(1100),
				}).setTween(new TimelineMax().to($('.page-index__description__text'), 1, {
					y: app.vw(150),

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#trigger',
					duration: app.vw(300),
					offset: app.vw(1500),
				}).setTween(new TimelineMax().from($('.page-index__video__image'), 1, {
					scale: 0.5,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#trigger',
					duration: app.vw(300),
					offset: app.vw(1900),
				}).setTween(new TimelineMax().to($('.page-index__video__image'), 1, {
					y: app.vw(-100),

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#trigger',
					duration: app.vw(300),
					offset: app.vw(2000),
				}).setTween(new TimelineMax().to($('.page-index__video__content'), 1, {
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(250),
					offset: app.vw(-200),
				}).setTween(new TimelineMax().to($('body'), 1, {
					backgroundColor: '#140008',

				}, 0).to($('.header, .page-index__ticker'), 1, {
					color: '#fff',

				}, 0).to($('.cursor'), 1, {
					backgroundColor: '#fff',

				}, 0).to($('.page-index__article'), 1, {
					color: '#fff',

				}, 0).from($('.page-index__article--1 .page-index__article__title__shadow'), 1, {
					y: app.vw(500),
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(150),
					offset: app.vw(-100),
				}).setTween(new TimelineMax().from($('.page-index__articles__small-dot'), 1, {
					y: app.vw(-500),
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(150),
					offset: app.vw(-100),
				}).setTween(new TimelineMax().from($('.page-index__team-image'), 1, {
					y: app.vw(300),
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(2900),
				}).setPin('.page-index__articles').addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(4600),
				}).setPin('.page-index__team-image').addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(400),
					offset: app.vw(300),
				}).setTween(new TimelineMax().to($('.page-index__article--1'), 1, {
					rotation: 15,
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(800),
					offset: app.vw(300),
				}).setTween(new TimelineMax().to($('.page-index__articles__small-dot'), 1, {
					rotation: 90,
					y: app.vw(-330),
					height: app.vw(660),
					width: app.vw(380),

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(800),
					offset: app.vw(300),
				}).setTween(new TimelineMax().to($('.page-index__team-image__mask'), 1, {
					width: app.vw(150),
					height: app.vw(150),

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(400),
					offset: app.vw(700),
				}).setTween(new TimelineMax().from($('.page-index__article--2'), 1, {
					rotation: -15,
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(400),
					offset: app.vw(1400),
				}).setTween(new TimelineMax().to($('.page-index__article--2'), 1, {
					rotation: 15,
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(800),
					offset: app.vw(1400),
				}).setTween(new TimelineMax().to($('.page-index__articles__small-dot'), 1, {
					rotation: 180,
					y: app.vw(-190),
					width: app.vw(660),
					height: app.vw(380),

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(800),
					offset: app.vw(1400),
				}).setTween(new TimelineMax().to($('.page-index__team-image__mask'), 1, {
					width: app.vw(280),
					height: app.vw(280),

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(400),
					offset: app.vw(1800),
				}).setTween(new TimelineMax().from($('.page-index__team-image__container'), 1, {
					opacity: 0,
					scale: 1.5,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(400),
					offset: app.vw(1800),
				}).setTween(new TimelineMax().from($('.page-index__article--3'), 1, {
					rotation: -15,
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(400),
					offset: app.vw(2500),
				}).setTween(new TimelineMax().to($('.page-index__article--3'), 1, {
					rotation: 15,
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(400),
					offset: app.vw(2500),
				}).setTween(new TimelineMax().to($('.page-index__articles__small-dot'), 1, {
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#articles-trigger',
					duration: app.vw(800),
					offset: app.vw(2500),
				}).setTween(new TimelineMax().to($('.page-index__team-image__mask'), 1, {
					width: '125vmax',
					height: '125vmax',

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#benefit-text-trigger',
					duration: app.vw(400),
					offset: app.vw(-400),
				}).setTween(new TimelineMax().from($('.page-index__team-image__shadow'), 1, {
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#benefit-text-trigger',
					offset: app.vh(400),
					duration: app.vw(1900),
				}).setPin('.page-index__benefit-text__container').addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#benefit-text-trigger',
					duration: app.vw(400),
					offset: app.vw(400),
				}).setTween(new TimelineMax().from($('.page-index__benefit-text__text svg'), 1, {
					strokeDashoffset: 1381,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#benefit-text-trigger',
					duration: app.vw(800),
					offset: app.vw(1000),
				}).setTween(new TimelineMax().to($('.page-index__benefit-text__text'), 1, {
					scale: 0.5,

				}, 0).to($('.page-index__benefit-text__text svg'), 1, {
					opacity: 0,

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#benefit-text-trigger',
					duration: app.vw(300),
					offset: app.vw(1100),
				}).setTween(new TimelineMax().to($('body'), 1, {
					backgroundColor: '#fff',

				}, 0).to($('.header, .page-index__ticker'), 1, {
					color: '#121212',

				}, 0).to($('.cursor'), 1, {
					backgroundColor: '#26272a',

				}, 0).to($('.page-index__benefit-text__text'), 1, {
					color: '#260d17',

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#history-trigger',
					duration: app.vw(400),
					offset: app.vw(650),
				}).setTween(new TimelineMax().from($('.page-index__history__text'), 1, {
					y: app.vw(200),

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#links-trigger',
					duration: app.vw(300),
					offset: app.vw(0),
				}).setTween(new TimelineMax().to($('body'), 1, {
					backgroundColor: '#140008',

				}, 0).to($('.header, .page-index__ticker'), 1, {
					color: '#fff',

				}, 0).to($('.cursor'), 1, {
					backgroundColor: '#fff',

				}, 0).to($('.page-index__links__text'), 1, {
					color: '#fff',

				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#footer-trigger',
					duration: app.vw(300),
					offset: app.vw(0),
				}).setTween(new TimelineMax().to($('body'), 1, {
					backgroundColor: '#fff',

				}, 0).to($('.header'), 1, {
					color: '#121212',

				}, 0).to($('.cursor'), 1, {
					backgroundColor: '#26272a',

				}, 0).to($('.page-index__ticker'), 1, {
					opacity: 0,

				}, 0).to($('.header__logo'), 1, {
					y: app.vh(400),
					x: app.vw(675),
					scale: 2,

				}, 0).to($('.header__logo__top'), 1, {
					color: '#CFB569',

					onReverseComplete() {
						$('.header__logo__top').removeAttr('style');
					},
				}, 0)).addTo(controller),]
			},
			indexMobile() {
				let $descriptionTitle = $('.page-index__description__title');

				let $teamImage = $('.page-index__team-image');

				let $teamImageContainer = $('.page-index__team-image__container');

				let $benefitText = $('.page-index__benefit-text__text');

				let $historyText = $('.page-index__history__text');

				$window.on('scroll', () => {
					let top = $window.scrollTop() + $window.outerHeight() * (3 / 5);

					if (top > $descriptionTitle.offset().top) {
						$descriptionTitle.addClass('is-animated');
					} else {
						$descriptionTitle.removeClass('is-animated');
					}

					if (top > $teamImage.offset().top) {
						$teamImageContainer.addClass('is-animated');
					} else {
						$teamImageContainer.removeClass('is-animated');
					}

					if (top > $benefitText.offset().top) {
						$benefitText.addClass('is-animated');
					} else {
						$benefitText.removeClass('is-animated');
					}

					if (top > $historyText.offset().top) {
						$historyText.addClass('is-animated');
					} else {
						$historyText.removeClass('is-animated');
					}
				});
			},
			about(controller) {
				let $introTitleLast = $('.page-about__intro__title__line ul li:last-child');

				return [
					new ScrollMagic.Scene({
						triggerElement: '#page-trigger',
						duration: app.vw(5000),
						// offset: app.vh(540),
						triggerHook: 0,
					}).setPin('.page-about__intro__content').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#page-trigger',
						duration: app.vw(200),
						offset: app.vw(100),
						triggerHook: 0,
					}).setTween(new TimelineMax().to($('.page-about__intro__scroll'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#page-trigger',
						duration: app.vw(4500),
						offset: app.vw(100),
						triggerHook: 0,
					}).setTween(new TimelineMax().to($('.page-about__intro__title__line ul'), 1, {
						x: 0 - $introTitleLast.offset().left + $window.outerWidth() + ($window.outerWidth() - $introTitleLast.outerWidth()) / 2,
						ease: Linear.easeNone,
					}, 0).from($('.page-about__intro__title__svg'), 1, {
						strokeDashoffset: 1774,
						ease: Linear.easeNone,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#benefit-trigger',
						duration: app.vw(600),
						offset: 0,
					}).setPin('.page-about__benefit__text').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#benefit-trigger',
						duration: app.vw(300),
						offset: app.vw(0),
					}).setTween(new TimelineMax().from($('.page-about__benefit__text'), 1, {
						scale: 0.8,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#image-trigger',
						duration: app.vw(400),
						offset: 0,
						triggerHook: 0,
					}).setPin('.page-about__image__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#image-trigger',
						triggerHook: 0,
						duration: app.vw(300),
						offset: app.vw(0),
					}).setTween(new TimelineMax().from($('.page-about__image__width'), 1, {
						width: '100%',
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#image-text-trigger',
						triggerHook: 0.75,
						duration: app.vw(200),
						offset: app.vw(0),
					}).setTween(new TimelineMax().from($('.page-about__image-text__row'), 1, {
						opacity: 0,
						y: app.vw(100),
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#benefit-item-1-trigger',
						duration: app.vw(800),
						offset: 0,
						triggerHook: 0.25,
					}).setPin('.page-about__benefit-item--1 .page-about__benefit-item__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#benefit-item-1-trigger',
						duration: app.vw(600),
						offset: app.vw(0),
						triggerHook: 0.25,
					}).setTween(new TimelineMax().from($('.page-about__benefit-item--1'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#benefit-item-2-trigger',
						duration: app.vw(800),
						offset: 0,
						triggerHook: 0.25,
					}).setPin('.page-about__benefit-item--2 .page-about__benefit-item__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#benefit-item-2-trigger',
						duration: app.vw(600),
						offset: app.vw(0),
						triggerHook: 0.25,
					}).setTween(new TimelineMax().from($('.page-about__benefit-item--2'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#image-3-trigger',
						duration: app.vw(400),
						offset: app.vw(0),
						triggerHook: 0.5,
					}).setTween(new TimelineMax().from($('.page-about__image-3__fix'), 1, {
						y: app.vw(400),
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#image-3-trigger',
						duration: app.vw(1200),
						offset: 0,
						triggerHook: 0,
					}).setPin('.page-about__image-3__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#footer-trigger',
						duration: $window.outerHeight() / 2,
						offset: 0,
					}).setTween(new TimelineMax().to($('.header__logo'), 1, {
						y: app.vh(320),
						x: app.vw(670),
						scale: 2,
					}, 0).to($('.header__logo__top'), 1, {
						color: '#CFB569',
						onReverseComplete() {
							$('.header__logo__top').removeAttr('style');
						},
					}, 0).to($('.page-index__ticker'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),
				];
			},
			digital(controller) {
				return [
					new ScrollMagic.Scene({
						triggerElement: '#page-trigger',
						duration: app.vh(100),
						offset: app.vh(600),
					}).setTween(new TimelineMax().to($('.back'), 1, {
						width: app.vw(90),
						height: app.vw(90),
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-word-trigger',
						duration: app.vw(2527),
						triggerHook: 0.3,
					}).setPin('.page-digital__we__word__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-word-trigger',
						duration: app.vw(2527),
						pushFollowers: false,
						triggerHook: 0.3,
					}).setPin('.page-digital__we__bg').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-word-trigger',
						duration: app.vw(2527),
						triggerHook: 0.3,
					}).setTween(new TimelineMax().to($('.page-digital__we__bg'), 1, {
						rotation: 180,
						ease: Linear.easeNone,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-1-trigger',
						duration: app.vw(300),
						triggerHook: 0.3,
					}).setPin('.page-digital__we__item--1 .page-digital__we__item__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-1-trigger',
						duration: app.vw(800),
						triggerHook: 0.9,
					}).setTween(new TimelineMax().from($('.page-digital__we__item--1 .page-digital__we__item__fix'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-2-trigger',
						duration: app.vw(300),
						triggerHook: 0.3,
					}).setPin('.page-digital__we__item--2 .page-digital__we__item__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-2-trigger',
						duration: app.vw(800),
						triggerHook: 0.9,
					}).setTween(new TimelineMax().from($('.page-digital__we__item--2 .page-digital__we__item__fix'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-3-trigger',
						duration: app.vw(300),
						triggerHook: 0.3,
					}).setPin('.page-digital__we__item--3 .page-digital__we__item__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-3-trigger',
						duration: app.vw(800),
						triggerHook: 0.9,
					}).setTween(new TimelineMax().from($('.page-digital__we__item--3 .page-digital__we__item__fix'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#footer-trigger',
						duration: $window.outerHeight() / 2,
						offset: 0,
					}).setTween(new TimelineMax().to($('.header__logo'), 1, {
						y: app.vh(320),
						x: app.vw(670),
						scale: 2,
					}, 0).to($('.header__logo__top'), 1, {
						color: '#CFB569',
						onReverseComplete() {
							$('.header__logo__top').removeAttr('style');
						},
					}, 0).to($('.back'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),
				];
			},
			estate(controller) {
				let workTweens = [];

				$('.page-estate__work__item').each((index, item) => {
					let $item = $(item);

					workTweens.push(
						new ScrollMagic.Scene({
							triggerElement: `#${$item.find('.page-estate__work__item__trigger').attr('id')}`,
							duration: app.vw(800),
							triggerHook: 0.9,
						}).setTween(new TimelineMax().from($item, 1, {
							opacity: 0,
						}, 0)).addTo(controller),
					)
				});

				return [
					new ScrollMagic.Scene({
						triggerElement: '#page-trigger',
						duration: app.vh(100),
						offset: app.vh(600),
					}).setTween(new TimelineMax().to($('.back'), 1, {
						width: app.vw(90),
						height: app.vw(90),
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#work-trigger',
						duration: $('.page-estate__work').outerHeight() - $window.outerHeight(),
						triggerHook: 0,
					}).setTween(new TimelineMax().to($('.page-estate__work'), 1, {
						backgroundPosition: `50% 100%`,
						ease: Linear.easeNone,
					}, 0)).addTo(controller),

					...workTweens,

					new ScrollMagic.Scene({
						triggerElement: '#we-word-trigger',
						duration: app.vw(2562),
						triggerHook: 0.3,
					}).setPin('.page-estate__we__word__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-1-trigger',
						duration: app.vw(300),
						triggerHook: 0.3,
					}).setPin('.page-estate__we__item--1 .page-estate__we__item__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-1-trigger',
						duration: app.vw(800),
						triggerHook: 0.9,
					}).setTween(new TimelineMax().from($('.page-estate__we__item--1 .page-estate__we__item__fix'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-2-trigger',
						duration: app.vw(300),
						triggerHook: 0.3,
					}).setPin('.page-estate__we__item--2 .page-estate__we__item__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-2-trigger',
						duration: app.vw(800),
						triggerHook: 0.9,
					}).setTween(new TimelineMax().from($('.page-estate__we__item--2 .page-estate__we__item__fix'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-3-trigger',
						duration: app.vw(300),
						triggerHook: 0.3,
					}).setPin('.page-estate__we__item--3 .page-estate__we__item__fix').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#we-item-3-trigger',
						duration: app.vw(800),
						triggerHook: 0.9,
					}).setTween(new TimelineMax().from($('.page-estate__we__item--3 .page-estate__we__item__fix'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#footer-trigger',
						duration: $window.outerHeight() / 2,
						offset: 0,
					}).setTween(new TimelineMax().to($('.header__logo'), 1, {
						y: app.vh(320),
						x: app.vw(670),
						scale: 2,
					}, 0).to($('.header__logo__top'), 1, {
						color: '#CFB569',
						onReverseComplete() {
							$('.header__logo__top').removeAttr('style');
						},
					}, 0).to($('.back'), 1, {
						opacity: 0,
					}, 0)).addTo(controller),
				];
			},
			handling(controller) {
				let serviceTweens = [];

				$('.page-handling__services__item').each((index, item) => {
					let $item = $(item);

					let $svg = $item.find('.page-handling__services__item__text svg');

					serviceTweens.push(
						new ScrollMagic.Scene({
							triggerElement: `#${$item.attr('id')}`,
							duration: app.vw(300),
							triggerHook: 0.5,
						}).setTween(new TimelineMax().from($svg, 1, {
							strokeDashoffset: parseInt($svg.data('dasharray'), 10),
						}, 0)).addTo(controller),
					);
				});

				return [
					new ScrollMagic.Scene({
						triggerElement: '#handling-video',
						duration: app.vw(400),
						offset: $('.page-handling__video__image').innerHeight() / 2,
					}).setPin('.page-handling__video').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#handling-video',
						duration: app.vw(400),
						offset: $('.page-handling__video__image').innerHeight() / 2,
					}).setTween(new TimelineMax().from($('.page-handling__video__image'), 1, {
						scale: 0.7,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#handling-video',
						duration: app.vw(400),
						offset: app.vw(500) + $('.page-handling__video__image').innerHeight() / 2,
					}).setTween(new TimelineMax().from($('.page-handling__video__title svg'), 1, {
						strokeDashoffset: 1143,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#handling-services',
						duration: app.vw(400),
						offset: 0,
						triggerHook: 0.35,
					}).setTween(new TimelineMax().from($('.page-handling__services__title__line svg'), 1, {
						strokeDashoffset: 1143,
					}, 0)).addTo(controller),

					...serviceTweens,

					new ScrollMagic.Scene({
						triggerElement: '#handling-consulting',
						duration: app.vw(800),
						triggerHook: 0.25,
					}).setPin('.page-handling__consulting').addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#handling-consulting',
						duration: app.vw(400),
						offset: 0,
						triggerHook: 0.25,
					}).setTween(new TimelineMax().to($('.page-handling__consulting__title__animate span'), 1, {
						opacity: 0.15,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#handling-consulting',
						duration: app.vw(400),
						offset: 0,
						triggerHook: 0.25,
					}).setTween(new TimelineMax().from($('.page-handling__consulting__title__svg'), 1, {
						strokeDashoffset: 2729,
					}, 0)).addTo(controller),

					new ScrollMagic.Scene({
						triggerElement: '#handling-consulting',
						duration: app.vw(400),
						offset: app.vw(400),
						triggerHook: 0.25,
					}).setTween(new TimelineMax().from($('.page-handling__consulting__title__svg-2 svg'), 1, {
						strokeDashoffset: 1810,
					}, 0)).addTo(controller),

					app.page.type.back(controller),

					app.page.type.footer(controller),
				];
			},
			handlingMobile() {
				const controller = new ScrollMagic.Controller({
					refreshInterval: 0,
				});

				$('.page-handling__services__item').each((index, item) => {
					let $item = $(item);

					let $svg = $item.find('.page-handling__services__item__text svg');

					new ScrollMagic.Scene({
						triggerElement: `#${$item.attr('id')}`,
						duration: app.vw(200),
						triggerHook: 0.6,
					}).setTween(new TimelineMax().from($svg, 1, {
						strokeDashoffset: parseInt($svg.data('dasharray'), 10),
					}, 0)).addTo(controller);
				});

				new ScrollMagic.Scene({
					triggerElement: '#handling-video',
					duration: app.vw(200),
					offset: $('.page-handling__video__image').innerHeight() / 2,
				}).setTween(new TimelineMax().from($('.page-handling__video__title svg'), 1, {
					strokeDashoffset: 1143,
				}, 0)).addTo(controller);

				new ScrollMagic.Scene({
					triggerElement: '#handling-services',
					duration: app.vw(200),
					offset: 0,
					triggerHook: 0.6,
				}).setTween(new TimelineMax().from($('.page-handling__services__title__line svg'), 1, {
					strokeDashoffset: 1143,
				}, 0)).addTo(controller);

				new ScrollMagic.Scene({
					triggerElement: '#handling-consulting',
					duration: app.vw(800),
					triggerHook: 0.15,
				}).setPin('.page-handling__consulting').addTo(controller);

				new ScrollMagic.Scene({
					triggerElement: '#handling-consulting',
					duration: app.vw(400),
					offset: 0,
					triggerHook: 0.25,
				}).setTween(new TimelineMax().to($('.page-handling__consulting__title__animate span'), 1, {
					opacity: 0.15,
				}, 0)).addTo(controller);

				new ScrollMagic.Scene({
					triggerElement: '#handling-consulting',
					duration: app.vw(400),
					offset: 0,
					triggerHook: 0.25,
				}).setTween(new TimelineMax().from($('.page-handling__consulting__title__svg'), 1, {
					strokeDashoffset: 2729,
				}, 0)).addTo(controller);

				new ScrollMagic.Scene({
					triggerElement: '#handling-consulting',
					duration: app.vw(400),
					offset: app.vw(400),
					triggerHook: 0.25,
				}).setTween(new TimelineMax().from($('.page-handling__consulting__title__svg-2 svg'), 1, {
					strokeDashoffset: 1810,
				}, 0)).addTo(controller);
			},
			footer(controller) {
				return new ScrollMagic.Scene({
					triggerElement: '#footer-trigger',
					duration: $window.outerHeight() / 2,
					offset: 0,
				}).setTween(new TimelineMax().to($('.header__logo'), 1, {
					y: app.vh(320),
					x: app.vw(670),
					scale: 2,
				}, 0).to($('.header__logo__top'), 1, {
					color: '#CFB569',
					onReverseComplete() {
						$('.header__logo__top').removeAttr('style');
					},
				}, 0)).addTo(controller);
			},
			back(controller) {
				return [new ScrollMagic.Scene({
					triggerElement: '#page-trigger',
					duration: app.vh(100),
					offset: app.vh(600),
				}).setTween(new TimelineMax().to($('.back'), 1, {
					width: app.vw(90),
					height: app.vw(90),
				}, 0)).addTo(controller),

				new ScrollMagic.Scene({
					triggerElement: '#page-trigger',
					duration: $window.outerHeight() / 2,
					offset: $('[data-content]').outerHeight() - $window.outerHeight(),
				}).setTween(new TimelineMax().to($('.back'), 1, {
					opacity: 0,
				}, 0)).addTo(controller),];
			},
			default(controller) {
				let result = [];

				if ($('.footer').length) {
					result.push(app.page.type.footer(controller));
				}

				if ($('.back').length) {
					app.page.type.back(controller).forEach((item) => {
						result.push(item);
					});
				}

				return result;
			},
		},
	},
	cursor: {
		init($cursor) {
			if (!app.mobile() && !$html.hasClass('is-device-tablet')) {
				app.cursor.constructor($cursor);
			}
		},
		constructor($cursor) {
			$cursor.show();
			$window.on('mousemove', (e) => {
				let cursorX = e.clientX + document.documentElement.scrollLeft;

				TweenMax.to($cursor, 0.5, {
					left: cursorX,
					top: e.clientY,
				});
			});
			$document.on('mouseenter', 'a, button', () => {
				TweenMax.to($cursor, 0.25, {
					scale: 0.1,
				});
			}).on('mouseleave', 'a, button', () => {
				TweenMax.to($cursor, 0.5, {
					scale: 1,
				});
			});
		},
	},
	video: {
		init($video) {
			let $button = $video.find('.js-video-button');

			let $image = $video.find('.js-video-image');

			window.videoMouseenter = false;

			if (!app.mobile()) {
				TweenMax.to($button, 0.5, {
					scale: 0,
				});
				$image.on('mouseenter', (e) => {
					let cursorX = e.clientX + document.documentElement.scrollLeft;

					$button.css({
						left: cursorX - $image.offset().left,
						top: e.clientY + document.documentElement.scrollTop - $image.offset().top,
					});
				}).on('mousemove', (e) => {
					let cursorX = e.clientX + document.documentElement.scrollLeft;

					if (!window.videoMouseenter) {
						window.videoMouseenter = true;
						TweenMax.to($('.cursor'), 0.5, {
							scale: 0,
						});
						TweenMax.to($button, 0.5, {
							scale: 1,
						});
					}
					TweenMax.to($button, 0.5, {
						left: cursorX - $image.offset().left,
						top: e.clientY + document.documentElement.scrollTop - $image.offset().top,
					});
				}).on('mouseleave', () => {
					window.videoMouseenter = false;
					TweenMax.to($button, 0.5, {
						scale: 0,
					});
					TweenMax.to($('.cursor'), 0.5, {
						scale: 1,
					});
				});
			}
		},
	},
	cookie: {
		init($cookie) {
			if (localStorage.cookiesAgree !== 'yes') {
				setTimeout(() => {
					$cookie.fadeIn(250);
				}, 2000);
			}
			$cookie.find('.cookies__btn button').on('click', (e) => {
				e.preventDefault();
				localStorage.cookiesAgree = 'yes';
				$cookie.fadeOut(250);
			});
		},
	},
	contacts: {
		init($contacts) {
			let $map = $contacts.find('.page-contacts__map__self');

			let $mapList = $contacts.find('.page-contacts__map__list a');

			let $mapListActive = $mapList.filter('.is-active');

			let mapOptions = $map.data();

			let map;

			app.scriptLoading('//api-maps.yandex.ru/2.1/?lang=ru_RU', () => {
				ymaps.ready(() => {
					map = new ymaps.Map($map[0], {
						center: [$mapListActive.data('lat'), $mapListActive.data('lng')],
						zoom: mapOptions.zoom,
						controls: ['zoomControl'],
					});

					$mapList.each((index, item) => {
						const $item = $(item);
						const itemData = $item.data();

						const polygonLayout = ymaps.templateLayoutFactory.createClass(`<div class="page-contacts__map__marker-label">${itemData.label}</div>`);

						const pm = new ymaps.Placemark([itemData.lat, itemData.lng], {}, {
							iconLayout: polygonLayout,
						});
						const pm2 = new ymaps.Placemark([itemData.lat, itemData.lng], {}, {
							iconLayout: 'default#image',
							iconImageHref: $map.data('icon'),
							iconImageSize: [81, 65],
							iconImageOffset: [-40.5, -32.5],
						});

						map.geoObjects.add(pm);
						map.geoObjects.add(pm2);
					});

					map.behaviors.disable('scrollZoom');

					$mapList.on('click', (e) => {
						let $this = $(e.currentTarget);

						e.preventDefault();
						$mapList.removeClass('is-active');
						$this.addClass('is-active');
						map.setCenter([$this.data('lat'), $this.data('lng')])
					});
				});
			});
		},
	},
	scriptLoading(src, callback) {
		let script = document.createElement('script');

		let loaded;

		script.setAttribute('src', src);
		if (callback) {
			script.onreadystatechange = script.onload = () => {
				if (!loaded) {
					callback();
				}
				loaded = true;
			};
		}
		document.getElementsByTagName('head')[0].appendChild(script);
	},
	form: {
		init($form) {
			let $successText = $form.find('.form__success-message');

			let init = () => {
				$form.find('.form-field input, .form-field textarea').each((iIndex, iItem) => {
					let $this = $(iItem);

					if ($this.val().trim().length) {
						$this.addClass('is-filled');
					}
				});

				$form.find('input[data-mask]').each((iIndex, iItem) => {
					let $iItem = $(iItem);

					let mask = $iItem.data('mask').toString();

					$iItem.inputmask(mask, {
						showMaskOnHover: false,
						clearIncomplete: true,
					});
				});
			};

			$form.on('focus', '.form__input__field input, .form__input__field textarea', (e) => {
				let $this = $(e.currentTarget);

				$this.addClass('is-filled');
			}).on('blur', '.form__input__field input, .form__input__field textarea', (e) => {
				let $this = $(e.currentTarget);

				setTimeout(() => {
					if (!$this.val().trim().length) {
						$this.removeClass('is-filled');
					}
				}, 50);
			});

			$form.on('change', '.form__file__button input[type="file"]', (e) => {
				let $this = $(e.currentTarget);

				let $parent = $this.closest('.form__file');

				let $list = $parent.find('.form__file__list');

				if (e.target.files.length === 0) {
					$list.html('');
				} else if (e.target.files.length === 1) {
					$list.html(`<div class="form__file__item">${e.target.files[0].name}
						<button class="form__file__item__remove" type="button"></button>
					</div>`);
				} else {
					$list.html(`<div class="form__file__item">${$parent.data('label')}${e.target.files.length}
						<button class="form__file__item__remove" type="button"></button>
					</div>`);
				}
			});

			$form.on('click', '.form__file__item__remove', (e) => {
				e.preventDefault();

				$(e.currentTarget).closest('.form__file').find('input[type="file"]').val('').trigger('change');
			});

			$form.on('click', '.form__hidden-trigger button', (e) => {
				let $this = $(e.currentTarget);

				e.preventDefault();
				$this.toggleClass('is-active');
				if ($this.hasClass('is-active')) {
					$this.text($this.data('on'));
					$form.find('.form__hidden').slideDown(250);
				} else {
					$this.text($this.data('off'));
					$form.find('.form__hidden').slideUp(250);
				}
			});

			init();

			let validator = $form.validate({
				lang: 'ru',
				submitHandler: (form) => {
					let preparedData;

					let processData;

					let contentType;

					if ($form.find('input[type="file"]').length > 0) {
						preparedData = new FormData($form.get()[0]);
						processData = false;
						contentType = false;
					}

					$.ajax({
						type: $form.attr('method'),
						url: $form.attr('action'),
						data: preparedData ? preparedData : $form.serialize(),
						dataType: 'json',
						processData,
						contentType,
						success(result) {
							$form.addClass('is-form-sended');
							$form[0].reset();
							$form.find('.form__submit__button').prop('type', 'button');
							$successText.text(result.message);
						},
					});
					// form.submit();
				},
			});
		},
	},
	adviseSlider: {
		init($slider) {
			let $arrow = $slider.find('.page-service__advise__arrow');

			let slider = new Swiper($slider.find('.swiper-container'), {
				slidesPerView: 1,
				navigation: {
					prevEl: $slider.find('.page-service__advise__prev'),
					nextEl: $slider.find('.page-service__advise__next'),
				},
			});

			TweenMax.to($arrow, 0.5, {
				scale: 0,
			});
			$slider.on('mouseenter', (e) => {
				let cursorX = e.clientX + document.documentElement.scrollLeft;

				$arrow.css({
					left: cursorX - $slider.offset().left,
					top: e.clientY + document.documentElement.scrollTop - $slider.offset().top,
				});
				TweenMax.to($('.cursor'), 0.5, {
					scale: 0,
				});
				TweenMax.to($arrow, 0.5, {
					scale: 1,
				});
			}).on('mousemove', (e) => {
				let cursorX = e.clientX + document.documentElement.scrollLeft;

				if (cursorX > $slider.outerWidth() / 2) {
					$arrow.addClass('is-right');
				} else {
					$arrow.removeClass('is-right');
				}
				TweenMax.to($arrow, 0.5, {
					left: cursorX - $slider.offset().left,
					top: e.clientY + document.documentElement.scrollTop - $slider.offset().top,
				});
			}).on('mouseleave', (e) => {
				TweenMax.to($arrow, 0.5, {
					scale: 0,
				});
				TweenMax.to($('.cursor'), 0.5, {
					scale: 1,
				});
			});
		},
	},
};

let initApps = () => {
	$('[data-app]').each((index, item) => {
		let $this = $(item);

		let split = $this.data('app').split('||');

		$.each(split, (appIndex, appName) => {
			if ($this.data(`${appName}-init`) !== true) {
				let appNameCamel = false;

				if (appName.indexOf('-') !== -1) {
					appNameCamel = appName.replace(/(-|\s)(\S)/ug, (m) => m.toUpperCase()).replace(/-/ug, '');
				}

				if (app[appName]) {
					app[appName].init($this);
					$this.data(`${appName}-init`, true);
				} else if (app[appNameCamel]) {
					app[appNameCamel].init($this);
					$this.data(`${appName}-init`, true);
				}
			}
		});
	});
};

app.global.init();

initApps();

